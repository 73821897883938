import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SvgIcons from "../components/svgIcons"

export default () =>
    <Layout>
        <Helmet>
            <title>dan & eleanor</title>

            <link rel="icon" href="/favicon/favicon-32.png" sizes="32x32"/>
            <link rel="icon" href="/favicon/favicon-57.png" sizes="57x57"/>
            <link rel="icon" href="/favicon/favicon-76.png" sizes="76x76"/>
            <link rel="icon" href="/favicon/favicon-96.png" sizes="96x96"/>
            <link rel="icon" href="/favicon/favicon-128.png" sizes="128x128"/>
            <link rel="icon" href="/favicon/favicon-192.png" sizes="192x192"/>
            <link rel="icon" href="/favicon/favicon-228.png" sizes="228x228"/>

            <link rel="shortcut icon" sizes="196x196" href="/favicon/favicon-196.png"/>

            <link rel="apple-touch-icon" href="/favicon/favicon-120.png" sizes="120x120"/>
            <link rel="apple-touch-icon" href="/favicon/favicon-152.png" sizes="152x152"/>
            <link rel="apple-touch-icon" href="/favicon/favicon-180.png" sizes="180x180"/>

            <meta name="msapplication-TileColor" content="#FFFFFF"/>
            <meta name="msapplication-TileImage" content="/favicon/favicon-144.png"/>

            <meta name="msapplication-config" content="/favicon/browserconfig.xml"/>
        </Helmet>
        <SvgIcons/>
        <nav className="navbar">
            <ul className="navbar__links">
                <li className="navbar__link--hidden-mobile">
                    <a className="navbar__link" href="#a-story">
                        <span className="navbar__ordinal">01</span>
                        <div className="navbar__divider"></div>
                        <div className="navbar__label">
                            <span className="navbar__title">How We Got Here</span>
                            <span className="navbar__text">A Story</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a className="navbar__link" href="#the-plan">
                        <span className="navbar__ordinal">02</span>
                        <div className="navbar__divider"></div>
                        <div className="navbar__label">
                            <span className="navbar__title">What's Going Down</span>
                            <span className="navbar__text"><span className="navbar__text--hidden-mobile">The</span> Plan</span>
                        </div>
                    </a>
                </li>
                <li className="navbar__link-divider"></li>
                <li>
                    <a className="navbar__link" href="#the-venues">
                        <span className="navbar__ordinal">03</span>
                        <div className="navbar__divider"></div>
                        <div className="navbar__label">
                            <span className="navbar__title">Where is it</span>
                            <span className="navbar__text">Venue <span className="navbar__text--hidden-mobile">Details</span></span>
                        </div>
                    </a>
                </li>
                <li className="navbar__link-divider"></li>
                <li>
                    <a className="navbar__link" href="#rsvp">
                        <span className="navbar__ordinal">04</span>
                        <div className="navbar__divider"></div>
                        <div className="navbar__label">
                            <span className="navbar__title">You Coming?</span>
                            <span className="navbar__text">RSVP</span>
                        </div>
                    </a>
                </li>
                <li className="navbar__link-divider"></li>
                <li>
                    <a className="navbar__link" href="#miscellaneous">
                        <span className="navbar__ordinal">05</span>
                        <div className="navbar__divider"></div>
                        <div className="navbar__label">
                            <span className="navbar__title">other details</span>
                            <span className="navbar__text">miscellaneous</span>
                        </div>
                    </a>
                </li>
            </ul>
        </nav>
        <div className="banner">
            <img className="logo" src="/daneleanor_logo.png" alt="Dan and Eleanor are getting married"/>
            <div className="banner__detail-group">
                <div className="banner__detail">
                    <span className="u-show-for-medium">Saturday, </span>Oct. 23rd
                </div>
                <div className="banner__detail">
                    Portland, <span className="u-hide-for-medium">OR</span><span className="u-show-for-medium">Oregon</span>
                </div>
            </div>
            <div className="banner__actions">
                <a href="#the-plan" className="btn__rsvp btn-version-1">Day-of Timeline</a>
                <a href="#the-venues" className="btn__rsvp btn-version-1">Venue Details</a>
            </div>
        </div>
        <div id="a-story" className="container u-mb12">
            <section className="flex-container u-justify-center">
                <div className="flex-item">
                    <article className="u-measure article u-mt3">
                        <h1 className="article__heading">
                            <svg className="u-mr-half" viewBox="0 0 35 35" width="35px">
                                <use xlinkHref="#icon-hearts"/>
                            </svg>
                            <span>a story</span>
                        </h1>
                        <p>
                            after five years of: falling in love, buying a house, raising a <a href="https://www.instagram.com/therealjunipurr/">cat</a>, traveling the world, 
                            getting hungry, getting hangry, eating food, getting food poisoning, getting food poisoning
                            on planes and just generally saying yes to everything together, Dan and Eleanor went on
                            another adventure. They traveled to Portugal, and spent two weeks getting lost &mdash; both 
                            literally and figuratively &mdash; in Lisbon and Porto before adjourning to the Douro
                            valley. Experiences were shared, wineries were visited and at the end of a very special private
                            dinner overlooking the picturesque estate, Dan got on one knee and asked Eleanor to say yes one
                            more time.
                        </p>
                    </article>
                </div>
                <div className="flex-item u-horizontalgap">&nbsp;</div>
                {/* <div className="flex-item u-show-for-large">
                    <div className="frame story__photo-primary">
                        <img src="story-photos/engaged_small.png" alt="Post engagement in Portugal"/>
                    </div>
                </div> */}
            </section>

            {/* <section className="flex-container">
                <div className="flex-item u-show-for-large secondary-item">
                    <div className="frame story__photo-secondary">
                        <img src="story-photos/nlights.jpg" alt="Northern lights in Tromso"/>
                    </div>
                </div>
            </section> */}

            <section className="flex-container ">
                <div className="flex-item u-horizontalgap">&nbsp;</div>
            </section>

            <section className="story__polaroids card-group">
                <article className="card">
                    <picture className="card__image">
                        <source srcSet="story-photos/beach.jpg" type="image/jpeg"/>
                        <img className="card__image" src="story-photos/beach.jpg" alt="Not long after we met"/>
                    </picture>
                    <div className="card__description">
                        budding romance (2016)
                    </div>
                </article>

                {/* <article className="card">
                    <picture className="card__image card__image--underlay">
                        <source srcSet="story-photos/portugal_wet.jpg" type="image/jpeg"/>
                        <img className="card__image" src="story-photos/portugal_wet.jpg" alt="Right before we got engaged"/>
                    </picture>
                    <div className="card__description">
                        right before engagement
                    </div>
                </article> */}

                <article className="card">
                    <picture className="card__image">
                        {/* <source srcSet="story-photos/still-engaged.webp" type="image/webp"/> */}
                        <source srcSet="story-photos/bikes.jpg" type="image/jpg"/>
                        <img className="card__image" src="story-photos/bikes.jpg" alt="Biking in Sunriver"/>
                    </picture>
                    <div className="card__description">
                        engaged, surviving 2020!
                    </div>
                </article>
            </section>





            <center>                
                <a href="https://gallery.dan-eleanor.com/" target="blank"><span className="u-smallcaps">More Pictures!</span></a>
            </center>
        </div>

        <section className="section u-fill--gray">
            <div id="the-plan" className="container">

                <div className="rolodex">
                    <div className="rolodex__card">10</div>
                    <div className="rolodex__card">23</div>
                    <div className="rolodex__card">21</div>
                </div>

                <h2 className="heading heading--medium u-text-center u-mt4 u-mb4 u-p1">
                    here’s the plan &mdash; good times ahead.
                </h2>

                <div className="timeline">
                    <div className="timeline__group">
                        <div className="timeline__icon">
                            <svg viewBox="0 0 30 35" width="30px">
                                <use xlinkHref="#icon-ceremony"/>
                            </svg>
                            <svg viewBox="0 0 33 35" width="33px">
                                <use xlinkHref="#icon-rings"/>
                            </svg>
                        </div>
                        <div className="timeline__event">
                            <div className="timeline__title heading heading--small">
                                <span className="timeline__time">6 pm</span>
                                Ceremony
                            </div>
                            <div className="timeline__description">
                                short ceremony to be held @ &nbsp;
                                {/* <br/> */}
                                <a href="#venue-blockhouse-pdx"><span className="u-smallcaps">Blockhouse PDX</span></a> 
                                &mdash; please don't arrive early! &nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>

                    <div className="timeline__group">
                        <div className="timeline__icon">
                            <svg viewBox="0 0 33 35" width="33px">
                                <use xlinkHref="#icon-drinks"/>
                            </svg>
                            {/* <svg viewBox="0 0 33 35" width="33px">
                                <use xlinkHref="#icon-dinner"/>
                            </svg> */}
                        </div>
                        <div className="timeline__event">
                            <div className="timeline__title heading heading--small">
                                <span className="timeline__time">6:30 pm</span>
                                Cocktail Hour
                            </div>
                            <div className="timeline__description">
                            cocktails and passed appetizers &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>



                    <div className="timeline__group">
                        <div className="timeline__icon">
                            <svg viewBox="0 0 33 35" width="33px">
                                <use xlinkHref="#icon-dinner"/>
                            </svg>
                        </div>
                        <div className="timeline__event">
                            <div className="timeline__title heading heading--small">
                                <span className="timeline__time">7:30 pm</span>
                                Dinner
                            </div>
                            <div className="timeline__description">
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Dinner served family style &nbsp; &nbsp; &nbsp; <br/> &nbsp;
                            </div>
                        </div>
                    </div>
                    




                    <div className="timeline__group">
                        <div className="timeline__icon">
                            <svg viewBox="0 0 33 35" width="33px">
                                <use xlinkHref="#icon-drinks"/>
                            </svg>
                            <svg viewBox="0 0 33 35" width="33px">
                                <use xlinkHref="#icon-balloons"/>
                            </svg>
                        </div>
                        <div className="timeline__event">
                            <div className="timeline__title heading heading--small">
                                <span className="timeline__time">9 pm</span>
                                Dessert &#38; Festivities
                            </div>
                            <div className="timeline__description">
                            Dancing, dessert, and late night snacks!  &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <br/>

                            </div>
                        </div>
                    </div>



                    <div className="timeline__group">
                        <div className="timeline__icon">
                            <svg viewBox="0 0 33 35" width="33px">
                                <use xlinkHref="#icon-drinks"/>
                            </svg>
                            <svg viewBox="0 0 33 35" width="33px">
                                <use xlinkHref="#icon-food"/>
                            </svg>
                        </div>
                        <div className="timeline__event">
                            <div className="timeline__title heading heading--small">
                                <span className="timeline__time">Sun</span>
                                Morning-after brunch
                            </div>
                            <div className="timeline__description">
                            open house style brunch starting at 11am @
                            <a href="https://goo.gl/maps/hNSbsMq2TTPekU5V8" target='blank'><span className="u-smallcaps">Our House.</span></a> drop in when you can!&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                           
                                <br/> &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>


                    {/* <div className="timeline__group">
                        <div className="timeline__icon">
                            <svg viewBox="0 0 33 35" width="33px">
                                <use xlinkHref="#icon-balloons"/>
                            </svg>
                            <svg viewBox="0 0 33 35" width="33px">
                                <use xlinkHref="#icon-drinks"/>
                            </svg>
                        </div>
                        <div className="timeline__event">
                            <div className="timeline__title heading heading--small">
                                <span className="timeline__time">6pm</span>
                                We drink
                            </div>
                            <div className="timeline__description">
                                doors open at <a href="#venue-brunch"><span className="u-smallcaps">Royal Queen</span></a>. grab a drink at the open bar before the banquet reception
                            </div>
                        </div>
                    </div>
                    <div className="timeline__group">
                        <div className="timeline__icon">
                            <svg viewBox="0 0 31 35" width="31px">
                                <use xlinkHref="#icon-dinner"/>
                            </svg>
                            <svg viewBox="0 0 33 35" width="33px">
                                <use xlinkHref="#icon-food"/>
                            </svg>
                        </div>
                        <div className="timeline__event">
                            <div className="timeline__title heading heading--small">
                                <span className="timeline__time">7pm</span>
                                We celebrate
                            </div>
                            <div className="timeline__description">
                                enjoy banquet festivities and dinner served family style at the
                                <br/>
                                <a href="#venue-brunch"><span className="u-smallcaps">Royal Queen</span></a>
                            </div>
                        </div>
                    </div> */}


                </div>
            </div>
        </section>

        <section className="section">
            <div  id="the-venues" className="container">
                <h2 className="heading heading--medium u-text-center u-mt4 u-mb4 u-p1">
                    where this is going down
                </h2>

                <article id="venue-blockhouse-pdx" className="venue">
                    <div className="venue__container flex-container u-justify-center">
                        <div className="venue__detail venue__location">
                            <picture className="venue__image">
                                <source srcSet="venue-photos/blockhouse.png" type="image/png"/>
                                <img className="venue__image" src="venue-photos/blockhouse.png" alt="Photo of Blockhouse"/>
                            </picture>
                            <div className="venue__description">
                                <div className="heading heading--x-small">
                                    Blockhouse PDX
                                </div>
                                <div>
                                    <a href="https://goo.gl/maps/NrvMwh83mZfHwoJ87" target="_blank">
                                        1988 NW 18th Ave, Portland, OR
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="venue__detail venue__map">
                            <picture className="venue__image">
                                <source srcSet="venue-photos/map.png" type="image/png"/>
                                <img className="venue__image" src="venue-photos/map.png" alt="Map of area around"/>
                            </picture>



                        </div>
                    </div>
                </article>



            </div>


        </section>





        <section className="section">
            <div  id="miscellaneous" className="container">

<div id="registry" className="container u-mb12">
            <section className="flex-container u-justify-center">
                <div className="flex-item">
                    <article className="u-measure article u-mt3">
                        <h1 className="article__heading">
                            <svg className="u-mr-half" viewBox="0 0 35 35" width="35px">
                                <use xlinkHref="#icon-drinks"/>
                            </svg>
                            <span>no gifts!</span>
                        </h1>
                        <p>
                            The only thing we're soliciting is your company. Please don't bring gifts or cash.
                        </p>
                    </article>
                </div>
                <div className="flex-item u-horizontalgap">&nbsp;</div>
            </section>



            <section className="flex-container u-justify-center">
                <div className="flex-item">
                    <article className="u-measure article u-mt3">
                        <h1 className="article__heading">
                            <span>accomodations:</span>
                        </h1>
                        <p>
                            There are many great hotels and airbnbs around Portland. Reach out to us for some suggestions!
                        </p>
                    </article>
                </div>
                <div className="flex-item u-horizontalgap">&nbsp;</div>
            </section>

            <section className="flex-container u-justify-center">
                <div className="flex-item">
                    <article className="u-measure article u-mt3">
                        <h1 className="article__heading">
                            <span>activities:</span>
                        </h1>
                        <p>
                            Oregon is home to world-class food, wine, beer and outdoor activities. We're happy to make some suggestions and can put you in contact with other guests with similar interests. Reach out to us for more information!
                        </p>
                    </article>
                </div>
                <div className="flex-item u-horizontalgap">&nbsp;</div>
            </section>




            <section className="flex-container u-justify-center">
                <div className="flex-item">
                    <article className="u-measure article u-mt3">
                        <h1 className="article__heading">
                            <span>COVID-19:</span>
                        </h1>
                        <p>
                            We hope to host this event as scheduled in October. We're also mindful and respectful of the health and safety of both our guests and our local community. The majority of our venue is considered outdoor space, and the size of our event should allow for ample distancing if necessary. Masks may be required depending on the current state of local and federal mandates.
                        </p>
                    </article>
                </div>
                <div className="flex-item u-horizontalgap">&nbsp;</div>
            </section>


            </div>




            </div>


        </section>






        <section className="section">
            <div  id="rsvp" className="container">

<div id="rsvp" className="container u-mb12">


            <section className="flex-container u-justify-center">
                <div className="flex-item">
                    <article className="u-measure article u-mt3">
                        <h1 className="article__heading">
                            <span>RSVPs:</span>
                        </h1>
                        <p>
                            RSVPs are currently closed &mdash; if you want or need to update us about your attendance please email or text message us. We're so excited to see you!
                        </p>
                    </article>
                </div>
                <div className="flex-item u-horizontalgap">&nbsp;</div>
            </section>

 
            </div>




            </div>


        </section>





        <footer className="u-mt1 u-mb1 u-text-center u-pb1">
            Made with love by dan & eleanor
        </footer>
    </Layout>
